import { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const MobileMnuButton = ({ isOpen, onClick, className }) => {
  useEffect(() => {}, [isOpen]);

  const first = useSpring({
    transform: isOpen ? 'translate(3px, 3px) rotate(45deg)' : 'translate(0px, 7px) rotate(0deg)',
  });
  const second = useSpring({
    transform: isOpen ? 'translate(3px, 18px) rotate(-45deg)' : 'translate(0px, 13px) rotate(0deg)',
  });

  return (
    <button className="pr-1" type="button" onClick={onClick}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="#041C2C"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.rect width="21" height="1" style={first} />
        <animated.rect width="21" height="1" style={second} />
      </svg>
    </button>
  );
};

export default MobileMnuButton;
